import React, { Component } from 'react';

const containerStyle = {
  width: '300px',
  padding: '20px',
  border: '1px solid #ccc',
  borderRadius: '8px',
  margin: 'auto',
  marginTop: '50px',
};

const inputStyle = {
  marginBottom: '10px',
};

const errorMessageStyle = {
  color: 'red',
  marginBottom: '10px',
};

const boxStyle = {
  marginTop: '10px',
};

const buttonStyle = {
  padding: '10px',
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
};

class FileUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFiles: [],
      fileContents: [],
      errorMessages: [],
    };
  }

  handleFileChange = (event) => {
    const files = event.target.files;

    if (files.length > 0) {
      const selectedFiles = Array.from(files);

      const invalidFiles = selectedFiles.filter(
        (file) =>
          !file.type.startsWith('image/png') && !file.type.startsWith('image/jpeg')
      );

      if (invalidFiles.length === 0) {
        this.setState({ selectedFiles, errorMessages: [] });
        this.readFilesContent(selectedFiles);
      } else {
        const errorMessages = invalidFiles.map(
          (file) => `${file.name} is not a valid PNG or JPEG file.`
        );
        this.setState({
          selectedFiles: [],
          fileContents: [],
          errorMessages,
        });
      }
    }
  };

  readFilesContent = (files) => {
    const readers = files.map((file) => {
      const reader = new FileReader();

      return new Promise((resolve) => {
        reader.onload = (e) => {
          const content = e.target.result;
          resolve(content);
        };

        reader.readAsText(file);
      });
    });

    Promise.all(readers).then((fileContents) => {
      this.setState({ fileContents });
    });
  };

  handleUpload = () => {
    alert(`Uploaded ${this.state.selectedFiles.length} photo to POKELOG!`)
    console.log('Files ready for upload:', this.state.selectedFiles);
  };

  render() {
    return (
      <div style={containerStyle}>
        <input
          type="file"
          accept="image/png, image/jpeg"
          onChange={this.handleFileChange}
          style={inputStyle}
          multiple
        />
        {this.state.errorMessages.length > 0 && (
          <div>
            {this.state.errorMessages.map((message, index) => (
              <p key={index} style={errorMessageStyle}>
                {message}
              </p>
            ))}
          </div>
        )}
        <div style={boxStyle}>
          <h4>Selected Files:</h4>
          {this.state.selectedFiles.map((file, index) => (
            <p key={index}>{file.name}</p>
          ))}
        </div>
        {/* <div style={boxStyle}>
          <h4>File Contents:</h4>
          {this.state.fileContents.map((content, index) => (
            <pre key={index}>{content}</pre>
          ))}
        </div> */}
        <button style={buttonStyle} onClick={this.handleUpload}>
          Upload
        </button>
      </div>
    );
  }
}

export default FileUploader;
