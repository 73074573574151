import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Home } from './components/home';
import { NoPage } from './components/noopage';
import { Pokelog } from './components/pokelog/pokelog.js';

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/pokelog" element={<Pokelog />} />
      <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
