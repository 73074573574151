import { NavLink } from 'react-router-dom'
import './navbar.css'

export const NavBar = () => {
  return (
    <nav className="navbar">
      <div className="container">
        {/* <h1>Timber Analytics</h1> */}
        {/* <div className="logo">
          <Brand />
        </div> */}
        <div className="nav-elements">
          <ul>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/pokelog">Pokelog</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
