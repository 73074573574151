import React, { Component } from "react";
import './pokelogMain.css'
import logo from './Pokeball.PNG'; // Tell webpack this JS file uses this image
import ScrollableGrid from "./pokelogComponents/scrollableGrid";
import FileUploader from "./pokelogComponents/fileUpload";

const data = [
    { id: 1, set: 'Base Set', card: 'Charizard', quantity: 1 },
    { id: 2, set: 'Base Set', card: 'Charizard', quantity: 1 },
    { id: 3, set: 'Base Set', card: 'Charizard', quantity: 1 },
    { id: 4, set: 'Base Set', card: 'Alakazam', quantity: 1 },

  ];

const headers = [
    { field: 'set', label: 'Set', width: '150px'},
    { field: 'card', label: 'Card', width: '150px' },
    { field: 'quantity', label: 'Quantity', width: '150px', editable: true },
];

const filterFields = ['set', 'card'];

export class PokelogMain extends Component {
    constructor(props){
        super(props);
        this.state = {
            idToken: this.props.idToken,
        }
    }

  callApi = async () => {
    try {
      // Make an API call with the user token
      const apiResponse = await fetch('https://gmiu43xsvb.execute-api.us-east-1.amazonaws.com/prod/getTest', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.idToken}`,
        },
      });

      const apiData = await apiResponse.json();
      console.log(apiData)
    } catch (error) {
      console.error('Error calling API:', error);
    }
  };


  render(){
    return (
        <div>
            <div className="pokelogHeader">
                <div className="left-content"> 
                    <img src={logo} className="left-image" alt="Logo" />
                    <p className="center-text">POKELOG</p>
                </div>
                < button className="right-button" onClick={() => this.props.signOutFunction()}>Sign Out</button>
            </div>
            <ScrollableGrid 
                data={data} 
                headers={headers} 
                filterPlaceholder="Filter..." 
                sortableHeaders={['set', 'card']}
                filterFields={filterFields}
                // filterField="card" // Specify the header being filtered
            />
            <FileUploader/>

        </div>
    );
  }
}

{/* < button onClick={this.callApi}>API TEST</button> */}
