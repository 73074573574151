import React, { Component } from "react";
import { Amplify } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { PokelogMain } from "./pokelogMain";

import { Hub, ConsoleLogger } from 'aws-amplify/utils';
import { getCurrentUser, decodeJWT, fetchAuthSession, signOut } from '@aws-amplify/auth';


Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: 'us-east-1_polPerTZe',
      userPoolClientId: '2vlponakhslcv27vs968dpvjca',
    },
  },
});

export class Pokelog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idToken: localStorage.getItem('idToken') || '',
    }

    Hub.listen('auth', this.listener);
  }
  logger = new ConsoleLogger('Logger', 'INFO');
  listener = (data) => {

    switch (data.payload.event) {
      case 'signedIn':
        this.logger.info('user signed in');
        this.state = {
          idToken: this.get_id_token()
        }
        break;
      case 'signUp':
        this.logger.info('user signed up');
        break;
      case 'signedOut':
        this.logger.info('user signed out');
        break;
      case 'signIn_failure':
        this.logger.info('user sign in failed');
        break;
      case 'configured':
        this.logger.info('the Auth module is configured');
        break;
      default:
        this.logger.error('Something went wrong, look at data object', data);
    }
  }

  get_id_token = async () => {
    try {
      const session = await fetchAuthSession();   // Fetch the authentication session
      this.setState({idToken: session.tokens.idToken.toString()},
      localStorage.setItem('idToken', session.tokens.idToken.toString())
        
      )
    }
    catch (e) {localStorage.setItem('idToken', '')}
  };

  get_current_user = async() => {
    const user = await getCurrentUser()
    return user;
  };

  handleSignout = () => {
    signOut()
  };

  componentDidMount() {
    this.get_id_token()
  }

  render(){      
    return (
      <Authenticator>
        <main>
          <PokelogMain idToken={this.state.idToken}  signOutFunction={this.handleSignout}/> 
        </main>
    </Authenticator>
    );
  }
}
