import React, { Component } from 'react';
import styled from 'styled-components';

const GridContainer = styled.div`
  max-width: 600px;
  margin: 20px auto;
  border: 1px solid #ddd;
`;

const GridHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f0f0f0;
  font-weight: bold;
  border-bottom: 1px solid #ddd; /* Add line under header */
`;


const GridFilter = styled.input`
  margin-bottom: 10px;
  padding: 5px;
  width: 70%; /* Adjust the width as needed */
`;

const GridBody = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;


const GridWrapper = styled.div`
  position: relative;
`;

const GridRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd; /* Add line under each row */

  &:last-child {
    border-bottom: none;
  }
`;

const GridColumn = styled.div`
  width: ${props => props.width || 'auto'};
  border-right: 1px solid #ddd;
  padding: 0 10px;
  display: flex;
  align-items: center;
  height: 100%; /* Ensure column separators extend from top to bottom */

  &:last-child {
    border-right: none; /* Remove border from the last column */
  }
`;

const ExportButton = styled.button`
  margin-left: 10px;
  padding: 8px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const EditableField = styled.input`
  border: 1px solid #ccc;
  padding: 5px;
  width: 80%; /* Adjust the width as needed */
`;

const DeleteButton = styled.button`
  margin-left: 10px;
  padding: 8px;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const RadioContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const RadioLabel = styled.label`
  margin-right: 10px;
`;

class ScrollableGrid extends Component {
    constructor(props) {
      super(props);
      this.state = {
            gridData: props.data || [],
            sortBy: null,
            sortOrder: 'asc', // Default sort order is ascending
            filterBy: '',
            selectedFilterField: props.filterFields[0] || '',      
        };
    }
  
    handleSort = (field) => {
      const { gridData, sortBy, sortOrder } = this.state;
      const { sortableHeaders } = this.props;
  
      // Check if the clicked column is in the list of sortable headers
      if (sortableHeaders.includes(field)) {
        let newSortOrder = 'asc';
        if (sortBy === field && sortOrder === 'asc') {
          newSortOrder = 'desc';
        }
  
        const sortedData = [...gridData].sort((a, b) => {
          const comparison = a[field].localeCompare(b[field], undefined, { numeric: true, sensitivity: 'base' });
          return sortOrder === 'asc' ? comparison : -comparison;
        });
  
        this.setState({
          gridData: sortedData,
          sortBy: field,
          sortOrder: newSortOrder,
        });
      }
    };
  
    handleFilter = (value, field) => {
      const { data } = this.props;
  
      // If a filter field is provided, filter based on that field; otherwise, use the default filter field
      const filteredData = data.filter((item) =>
        field ? item[field].toLowerCase().includes(value.toLowerCase()) : true
      );
  
      this.setState({
        gridData: filteredData,
        filterBy: value,
        filterField: field,
      });
    };

    handleEdit = (rowIndex, field, value) => {
        const { gridData } = this.state;
        const updatedData = [...gridData];
        updatedData[rowIndex][field] = value;
        this.setState({ gridData: updatedData });
    };
    
    handleDelete = (rowIndex) => {
        const { gridData } = this.state;
        const updatedData = [...gridData];
        updatedData.splice(rowIndex, 1);
        this.setState({ gridData: updatedData });
    };

    handleRadioChange = (field) => {
        this.setState({ selectedFilterField: field });
    };
  
    exportToCSV = () => {
        const { headers } = this.props;
        const { gridData } = this.state;
    
        const csvContent = `data:text/csv;charset=utf-8,${headers.map(header => header.label).join(',')}\n${gridData.map(row => headers.map(header => row[header.field]).join(',')).join('\n')}`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'export.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    
      render() {
        const { gridData, sortBy, sortOrder, filterBy, selectedFilterField } = this.state;
        const { headers, filterFields, filterPlaceholder } = this.props;
    
        return (
          <GridWrapper>
            <GridContainer>
              <RadioContainer>
                {filterFields.map((field) => (
                  <div key={field}>
                    <input
                      type="radio"
                      id={field}
                      name="filterField"
                      value={field}
                      checked={selectedFilterField === field}
                      onChange={() => this.handleRadioChange(field)}
                    />
                    <RadioLabel htmlFor={field}>{field}</RadioLabel>
                  </div>
                ))}
              </RadioContainer>
              <GridFilter
                type="text"
                placeholder={filterPlaceholder || 'Filter by Name'}
                value={filterBy}
                onChange={(e) => this.handleFilter(e.target.value, selectedFilterField)}
              />
              <ExportButton onClick={this.exportToCSV}>Export to CSV</ExportButton>
              <GridHeader>
                {headers.map((header, index) => (
                  <GridColumn key={header.field} width={header.width}>
                    <div onClick={() => this.handleSort(header.field)}>
                      {header.label} {sortBy === header.field && (sortOrder === 'asc' ? '▼' : '▲')}
                    </div>
                  </GridColumn>
                ))}
                <GridColumn width={10}>
                  <div>Actions</div>
                </GridColumn>
              </GridHeader>
              <GridBody>
                {gridData.map((item, rowIndex) => (
                  <GridRow key={item.id}>
                    {headers.map((header, colIndex) => (
                      <GridColumn key={header.field} width={header.width}>
                        {header.editable ? (
                          <EditableField
                            value={item[header.field]}
                            onChange={(e) => this.handleEdit(rowIndex, header.field, e.target.value)}
                          />
                        ) : (
                          item[header.field]
                        )}
                      </GridColumn>
                    ))}
                    <GridColumn width={10}>
                      <DeleteButton onClick={() => this.handleDelete(rowIndex)}>Delete</DeleteButton>
                    </GridColumn>
                  </GridRow>
                ))}
              </GridBody>
            </GridContainer>
          </GridWrapper>
        );
      }
    }
    
    export default ScrollableGrid;